import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Sculpture = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>paper protoype: a kind of sculpture (project A)</h2>
        <p>For next week, protoype your idea of <Link to="../sculpture">Project A</Link> on paper.</p>
        <p>Print out one (or many) of the templates below. Communicate your idea so that someone can understand it from your paper protoype alone. Use the white space on the template to explain intended interactions, movements, or transitions.</p>
        
        <p>Bring your prototype to the next class. If you are remotely studying, please scan your work, add it to your repo, and push a link to the <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">Class Wiki</a>.</p>
        <br></br>
        <p>Templates for the paper prototype:</p>
        <ul>
          <li>
            <p><a href="../../abc-pp-a3-landscape.pdf" target="_blank">A3 Landscape</a></p>
          </li>
          <li>
            <p><a href="../../abc-pp-a3-portrait.pdf" target="_blank">A3 Portrait</a></p>
          </li>
          <li>
            <p><a href="../../abc-pp-a4-landscape.pdf" target="_blank">A4 Landscape</a></p>
          </li>
          <li>
            <p><a href="../../abc-pp-a4-portrait.pdf" target="_blank">A4 Portrait</a></p>

          </li>
        </ul>
      </MiniLayout>
    </Layout>
  )
}



export default Sculpture